<template>
  <alert-edit-create :organization-id="$auth.me.organization_id" />
</template>
<script>
import AlertEditCreate from '~/pages/shared/alerts/partials/EditCreate';

export default {

  components: {
    AlertEditCreate,
  },

  middleware: 'employee',
};
</script>
